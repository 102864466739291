$kontakt-icons--a-color-hover: theme('primary-dark') !default;
$kontakt-icons--a-color: theme('primary') !default;

$kontakt-icons--a-color-dark: theme('secondary') !default;
$kontakt-icons--a-color-hover-dark: theme('secondary-dark') !default;
.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $kontakt-icons--a-color;
    transition: color 0.3s;
  }
  a:hover {
    color: $kontakt-icons--a-color-hover;
  }
  i {
    padding: 0px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    svg {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
  .text {
    font-size: 16px;
    text-align: center;
  }
}

.dunkle-icons {
  a {
    color: $kontakt-icons--a-color-dark !important;
  }

  a:hover {
    color: $kontakt-icons--a-color-hover-dark !important;
  }
}