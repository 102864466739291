.tickets-img {
  position: relative;
  height: auto;
  margin-top: -40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 50%;
  picture {
    img {
      width: 100%;
      height: auto;
    }
  }
}