$primary-color: #eb2323;
$primary-color-light: rgb(153, 0, 0);
$primary-color-dark: rgb(153, 0, 0);
$secondary-color: #157178 !default;
$secondary-color-dark: #004750 !default;
$header--height: 160px;
$header--bg-color: #ddc8a2;

$nav--bg-color: #004750;
$nav-main--font-family: 'Raleway';
$nav-main--font-weight: 900;
$nav-main--font-size: 20px;

$footer--bg-color: #eb2323;
$font-family-base: 'Raleway';
$h1--font-family: 'Raleway';
$h2--font-family: $h1--font-family;
$font-weight-base: 400;
$headings-font-weight: 400;
$font-size-base: 1.1rem !important;

$farbe-1: #eb2323;
$farbe-1-hover: rgb(153, 0, 0);
$farbe-2: #dfaf46;
$farbe-3: #999;

$link-color: $farbe-1;

$farbe-tct: #666666;
$farbe-tct-hover: #eb2323;

$a--color: #000000;
$a-hover--color: $farbe-1-hover;


@import "modules/header.scss";
@import "modules/kontakt_icons";
//@import "modules/logocloud";
@import "modules/teaser";
@import "modules/teaser-halb";
@import "navs/nomenue";
@import "modules/video";
@import "modules/infobox";
@import "modules/news";
@import "modules/sitemap";
@import "modules/iconsmitlinks";
@import "modules/bilderslider";
@import "modules/box";
@import "modules/stoerer-online-tickets";
@import "modules/tickets";

$kontakt-icons--a-color-dark: #eb2323;
$kontakt-icons--a-color-dark--hover: #43b1b3;

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1500px
);

html, body {
  overscroll-behavior-y: none;
}
body {
  font-size: 1.1rem !important;
  overscroll-behavior-y: none;

  .mobile-footer {
    display: none;
  }
  .modal-header {
    border-bottom: 0px;
    button {
      padding: 0px !important;
      background: none !important;
      border: 0px !important;
      margin-top: -40px !important;
      margin-right: -10px !important;
      span {
        font-size: 50px !important;
      }
    }
  }
  .modal-content {
    margin-top: 50%;
  }
  .modal-body {
    h1 {
      margin-top: 0px;
    }
  }

  h1 {
    color: rgb(235,35,35);
  }
  h2 {
    color: $farbe-1-hover;
  }
  header {
    position: fixed !important;
    z-index: 999;
    background: url('../../../media/bg_top.jpg') !important;
    width: 100%;
    .header__top {
      position: relative;
      .logo-left {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        display: flex;
        width: 100%;
        margin-left: 20px;
        max-width: 170px;
        .devider {
          border-left: 1px solid #ccc;
          height: 100%;
          margin: 0px 10px;
        }
        img, svg {
          margin: 0px 10px;
          height: 50px;
          .st0 {
            fill: $farbe-tct;
          }
        }
      }
      .logo-right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        display: flex;
        margin-right: 20px;
        width: 100%;
        max-width: 170px;
        justify-content: flex-end;
        a {
          color: $farbe-tct;
          transition: color 0.3s;
          img, svg {
            margin: 0px 10px;
            height: 50px;
          }
        }
        a:hover {
          color: $farbe-tct-hover;
        }
      }
    }
    .header__largelogo {
      width: 700px;
      position: absolute;
      left: 50%;
      top: 10px;
      transform: translateX(-50%);
      a {
        picture {
          position: relative;
          img {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      img {
        width: 100%;
      }
    }
    .navbar-toggler {
      position: fixed;
      top: 20px;
      left: 50px;
      z-index: 9999;
      color: #fff4e0;
      background: #fff !important;
      color: #eb2323;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(235,35,35, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .header--icons {
      display: none !important;
    }
    @include media-breakpoint-up(lg) {
      .hidden-lg {
        display: none !important;
      }
    }
    .nav-shadow {
      pointer-events: none;
      position: absolute;
      top: 0px;
      width: 100%;
      img {
        position: absolute;
        top: 0px;
        width: 100%;
      }
    }
    nav {
      margin-top: 0px;
      font-size: 30px;
      font-family: $font-family-base;
      color: #117179;
      background: transparent !important;
      text-transform: uppercase;
      flex-direction: row;
      justify-content: space-between;
      z-index: 999;
      height: 60px !important;
      #navbarSupportedContent {
        height: 100%;
        visibility:hidden;
        opacity:0;
        transition:visibility 0.3s linear,opacity 0.3s linear;
        //display: flex;
        ul {
          width: 100% !important;
          margin-right: 0px!important;
          margin-left: 0px!important;
          padding-left: 0px;
          padding-right: 0px;
          max-width: unset !important;
          justify-content: space-around;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $farbe-2;
            width: calc(100% / 3);
            text-shadow: -1px -1px 0 #9c7622;
            a {
              color: #ffffff;
            }
            a:hover {
              color: rgba(255,255,255,0.75) !important;
            }
          }
          li:first-child {
            background: $farbe-1;
            text-shadow: -1px -1px 0 #b01a1a;
          }
          li:last-child {
            background: $farbe-3;
            text-shadow: -1px -1px 0 #2f2106;
          }
        }
      }
      .collapsing {
        height: 100% !important;
        visibility:visible;
        opacity:1;
      }
      .collapse {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .navbar-nav {
        flex-direction: row !important;
        justify-content: space-evenly;
        height: 100%;
      }
    }
    #headerlogo {
      display: flex;
      justify-content: center !important;
      position: relative;
      z-index: 99;
      height: 100%;
      top: 10px;
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 80%;
          margin-top: 0px;
          width: unset !important;
        }
      }
    }
  }
  .header--icons {
    display: flex !important;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;

    .kontakticons {
      font-size: 30px !important;
      padding-top: 25px !important;
      padding-right: 25px !important;
    }
    a:hover {
      color: #fff4e0 !important;
    }
    a {
      display: flex;
      color: #fff4e0 !important;
      i {
        transform: unset !important;
        padding: 0px 10px 10px;
        width: 40px !important;
        left: unset !important;

        img, svg {
          height: 30px !important;
          vertical-align: top !important;
        }
      }
    }
  }
  .modal {
    .modal-header {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .modal-body {
      margin-top: 0px;
      padding-top: 0px;
    }
    h1 {
      font-size: 40px;
      font-weight: 900;
      color: rgb(235,35,35);
      margin-top: 60px;
      margin-top: 0px !important;
    }
    h2 {
      font-size: 25px;
      font-weight: 700;
      color: $farbe-1-hover;
    }
  }
  main {
    padding-top: 160px;
    background: #ffffff !important;
    z-index: 2;
    .muensterbus-skyline {
      position: relative;
      margin-top: -20px;
      picture {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .gutschein-bild {
      margin-top: -40px; margin-bottom: -60px;
      img {
        display: block; margin-left: auto; margin-right: auto;
        width: 96%;
        height: auto;
      }
    }
    h1 {
      font-size: 40px;
      font-weight: 900;
      color: rgb(235,35,35);
      margin-top: 60px;
    }
    h2 {
      font-size: 25px;
      font-weight: 700;
      color: $farbe-1-hover;
    }
    h4 {
      text-align: center;
      font-weight: 700;
      font-size: 25px;
      font-family: 'Raleway';
    }
    .tinyMCE:first-child {
      h1 {
        margin-top: 60px;
      }
    }
    .kontakticons {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      i {
        width: unset !important;
        img,svg {
          height: 100px;
        }
      }
      a {
        color: #006373 !important;
      }
      a:hover {
        color: #43b1b3 !important;
      }
    }
    .tinyMCEbackground-1,
    .tinyMCEbackground-2,
    .tinyMCEbackground-3 {
      height: 100%;
      padding: 30px;
      color: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      hr {
        width: 100%;
      }
      h2 {
        color: #fff !important;
      }
    }
    hr {
      margin-bottom: 30px;
    }
    .tinyMCEbackground-1 {
      background: rgba(0,99,115, 1.0);
    }
    .tinyMCEbackground-2 {
      background: rgba(67,177,179, 1.0);
    }
    .tinyMCEbackground-3 {
      background: rgba(234,91,143, 1);
    }
    .grid-container {
      z-index: 99;
      background-color: #ffffff;
    }
    .container-fluid {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  .container-color-1:before,
  .container-color-2:before,
  .container-color-3:before,
  .container-color-4:before {
    font-weight: bold;
    font-size: 300px;
    line-height: 286px;
    margin-left: -10px;
    position: absolute;
  }
  .container-color-1, .container-color-2, .container-color-3 {
    position: relative;
    display: flex;
    >div {
      position: relative;
    }
  }

  .container-color-1 {
    background: #c6c6c6 !important;
  }
  .container-color-1:before {
    color: #f2f2f1;
  }

  .container-color-2 {
    background: #e5e5e5 !important;
  }
  .container-color-2:before {
    color: #cbbc97;
  }

  .container-color-3 {
    background: url("../../../media/bg_content.webp") repeat-x;
    background-color: #fff;
    .uppercase {
      color: $header--bg-color !important;
    }
  }
  .container-color-4{
    background: transparent !important;
  }
  .container-color-3:before {
    line-height: 282px;
  }

  .header--text {
    left: 200px !important;
    top: 80px !important;
    transform: none !important;
    color: $header--bg-color;
  }
  footer {
    text-align: center;
    color: #ffffff;
    padding-top: 15px !important;
    margin: 0px 0px 0px 0px !important;
    background: $footer--bg-color !important;
    z-index: 999999;
    padding-bottom: 30px !important;
    .footer--shadow {
      position: absolute;
      top: 0px;
      width: 100%;
      transform: rotate(180deg) translateY(100%);
      picture {
        width: 100%;
        img {
          width: 100%;
          height: 30px;
        }
      }
    }
    h1, h2, h3, h4, h5, h6 {
      color: #fff !important;
    }
    a {
      color: #ffffff !important;
      font-weight: 700;
    }
    .logo-footer {
      position: relative;
      margin-top: -100px;
      margin-left: auto;
      margin-right: auto;
      z-index: 999;
      img {
        width: 220px;
      }
    }
  }
  .ilu {
    width: 350px;
    height: 286px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    img {
      width: 100%;
      z-index: 99999;
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  }

  .visible img {
    opacity: 1;
    transform: translateY(0px) !important;
  }

  #table-mauibeach_mailcatcher {
    width: 450px;
    max-width: calc(100% - 10px);
    height: 150px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 50%;
    transform: translateX(-50%);
    .form-group {
      width: calc(100% - 130px);
      .control-label {
        width: 100%;
        color: rgb(47,47,48);
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
      .form-control {
        width: 100%;
        height: 50px;
        padding-left: 20px;
      }
    }
    .btn-primary {
      height: 50px;
      margin-top: 38px;
      margin-left: 10px;
      color: rgb(0,0,0);
      background-color: rgba(190,188, 194, 0);
      border: 1px solid rgb(95, 94, 97);
    }

    .bird {
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
    }
  }
  .events {
    .event {
      display: flex;
      flex-direction: row;
      margin-bottom: 100px;
      .headline {
        font-size: 40px;
        line-height: 27px;
        margin-bottom: 10px;
        color: #006373;
        font-weight: 600;
      }
      .datum {
        font-weight: 600;
        font-size: 30px;
        color: $footer--bg-color;
      }
      .bild {
        display: flex;
        picture {
          position: relative;
          img {
            position: relative;
            width: 100%;
          }
        }
      }
      .btn {
        width: 100% !important;
        background: $footer--bg-color !important;
        color: #ffffff;
        font-size: 30px !important;
        border: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
        border-radius: 0px 0px 10px 10px;

        font-weight: 600 !important;
      }
    }
  }
  .nextevent {
    padding: 20px;
    color: #fff !important;
    justify-content: center;
    display: flex;
    .headline {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
    }
    .date {
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px;
    }
    .ticketbtn {
      text-align: center;
      justify-content: center;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      a {
        width: 100%;
        .btn {
          color: #fff !important;
          font-size: 1.1rem !important;
          width: 100%;
          padding-top: 12px !important;
          padding-bottom: 12px !important;
          background: #006373;
          border-color: #006373;
        }
      }
    }
  }
  .nextevent-bg-0 {

  }
  .nextevent-bg-1 {
    background: rgba(0,99,115, 1.0);
    color: #fff;
    .btn-primary {
      background: rgba(67,177,179,1);
    }
  }
  .nextevent-bg-2 {
    background: rgba(67,177,179, 1.0);
  }
  .nextevent-bg-3 {
    background: rgba(234,91,143, 1);
  }

  .bildergalerie--article {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    .teaser--bg {
      width: 100%;
      height: 100%;
      position: fixed;
      opacity: 0;
      transition: opacity 0.6s;
      display: flex;
      object-fit: cover;
      picture {
        height: 100%;
        width: 100%;
        object-fit: cover;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .active {
      opacity: 1;
    }
  }
}