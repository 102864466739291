.modul-box {
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  padding: 20px 20px 20px 20px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 40px);
  display: flex;
  align-items: stretch;
  a {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    h3 {
      font-size: 25px;
      font-weight: 700;
      color: #eb2323;
      text-align: center;
      margin-top: 10px;
    }

    .beschreibung {
      font-weight: normal !important;
      flex-grow: 2;
      margin-bottom: 10px;
    }

    .job-bild {
      position: relative;

      picture {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .box--verlinkung {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      svg {
        max-width: 60px;
        max-height: 60px;
        height: 60px;
        width: 60px;
        z-index: 1;
      }
    }
    .box--verlinkung-line {
      width: 100%;
      border-top: 1px solid #ccc;
      height: 1px;
      position: absolute;
    }
  }
}