footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  min-height: 50px;
  .kontakticons {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  .site-map li a:hover,
  a:hover {
    color: $farbe-2 !important;
  }
  .site-map li a,
  a {
    font-weight: 700 !important;
    color: #ffffff !important;
    transition: color 0.3s;
  }

  .site-map li a {
    font-weight: 900 !important;
  }
}