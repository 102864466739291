.stoerer-online-tickets {
  position: absolute;
  top: -220px;
  left: -110px;
  width: 300px;
  height: 300px;
  z-index: 999;

  img {
    position: absolute;
    z-index: 100;
  }

  .stoerer-shadow {
    z-index: 1;
    position: absolute;
    left: 5px;
    top: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    box-shadow: 0px 0px 10px #000;
    border-radius: 150px;
  }
}

.container-fluid {
  .stoerer-online-tickets {
    left: 30px !important;
    top: -280px !important;
  }
}

@include media-breakpoint-down(lg) {
  .stoerer-online-tickets {
    top: -110px !important;
    left: -10px !important;
    width: 150px !important;
    height: 150px !important;
  }

  .container-fluid {
    .stoerer-online-tickets {
      left: 10px !important;
      top: -130px !important;
    }
  }
}