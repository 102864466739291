@include media-breakpoint-down(md) {
  body {
    .header--icons {
      display: none !important;
    }
    main {
      padding-top: 50px !important;
      .container-fluid {
        >.row {
          margin: 0px !important;
        }
      }
      .kontakticons {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 40px !important;
          i {
            justify-content: center;
            display: flex;
          }
        }
      }
      .tinyMCE {
        padding: 0px 10px !important;
      }
    }
    .mobile-footer {
      
    }
    .sitemap--nebeneinander {
      li {
        h5 {
          a {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  body {
    main {
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 20px !important;
      }
    }
    @include media-breakpoint-down(sm) {
      main {
        .gutschein-bild {
          margin-top: -20px; margin-bottom: -20px;
          img {
            display: block; margin-left: auto; margin-right: auto;
          }
        }
      }
      #header {
        height: 50px !important;
        max-width: 100vw !important;

        .header__top {
          height: 50px !important;
        }

        #headerlogo {
          width: 100%;

          img {
            height: 33.65px !important;
            width: 375px !important;
          }
        }
      }
      .bildergalerie--article {
        width: 100vw !important;
        height: 100vh !important;
        overflow: hidden;

        .teaser--bg {
          max-width: 100vw !important;
          max-height: 100vh !important;
        }
      }

      .teaser--main {
        .teaser {
          min-height: 280px !important;
          max-height: 450px !important;
        }
      }
    }
    header {
      .mobile-shadow {
        width: 100%;
        position: fixed;
        height: 80px;
        bottom: 0px;
        background: transparent;
        display: flex !important;
        box-shadow: 0px 0px 10px #000;
        z-index: 9999;
      }
      .navbar-toggler {
        position: fixed;
        bottom: 20px;
        top: unset;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #fff !important;
      }
      .logo-left, .logo-right {
        position: fixed !important;
        bottom: 0px;
        top: unset !important;
        transform: unset !important;
        background: $primary-color;
        width: 50% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 10px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        height: 80px !important;
        max-width: unset !important;
        img, svg {
          max-width: 30px !important;
          .st0 {
            fill: #fff !important;
          }
        }
        .devider {
          display: none;
        }

      }
      .logo-right {
        right: 0px !important;
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
      nav {
        height: auto !important;
        .nav-shadow {
          img {
            height: 25px !important;
          }
        }
        .navbar-nav {
          box-shadow: 0px 0px 10px #000;
        }
        .navbar-collapse {
          position: fixed;
          bottom: 80px !important;
          top: unset !important;

          height: auto !important;
          width: 100% !important;
          background: unset !important;
          padding: 20px 0px 20px;
          padding-bottom: 0px !important;
          ul {
            flex-direction: column !important;
            li {
              width: 100% !important;
              padding: 20px 0px;
              a {
                font-size: 18px !important;
              }
            }
          }
        }
      }
    }
    .news-startseite {
      .news {
        width: calc(100% - 20px);
        margin-bottom: 30px;
      }
    }
    .kontakticons {
      flex-direction: column;
    }
    footer {
      min-height: 520px !important;
      padding-bottom: 100px !important;
    }

    .sitemap--nebeneinander {
      flex-direction: column !important;
    }
  }
}